
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {loadPaginatedClaimedECCOrders, claimECCOrder}  from "@/api/estore";
import EstoreTabNavigator from "@/components/cards/EstoreTabNavigator.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "eccordersclaim",
  components: {
    EstoreTabNavigator,
  },
  data() {
    return { 
      list : [],
      loading: true,
      isBCOAccount : false,
      to_date :  new Date().toISOString().substr(0, 10),
      from_date: new Date().toISOString().substr(0, 10),
      order_id : ""
    }
  },
  mounted(){
      this.eccOrdersClaimHistory();
      
  },
  methods : {
    async eccOrdersClaimHistory(){
      this.loading = true; 
      let params = {
        "page" : 1, 
        "f" : this.from_date + " 00:00:00",
        "t" : this.to_date + " 23:59:59"
      }

      
      const response = await loadPaginatedClaimedECCOrders(params);
      console.log(response.data.pageList);
      this.list = response.data.pageList;
      this.loading = false;
    },
    async claimECCOrder(){
      this.loading = true;
      let params = {
        "order_id" : this.order_id
      }

      const response = await claimECCOrder(params);

      if(response != null){
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            }); 
            this.eccOrdersClaimHistory();
            this.order_id = "";
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
            }); 
          } 
      }

      this.loading = false;
    }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("reorderTab") || "0");
      setCurrentPageTitle("ECC Order Claim");
      
    });
    
  }
});
