
import { defineComponent, computed } from "vue";
import {loadPaginatedEstore, getIGlowVotingPower} from "@/api/estore"
import {getUserCountryCode} from "@/api/dashboard"

export default defineComponent({
  name: "estoretabnavigator",
  components: {},
  props: {
  },
  data(){
    return{
      currentRank: "",
      isBCOAccount: false,
      votingPower : 0,
      votingON : false, /* MAKE THIS TRUE IF VOTING IS OFFICIALLY ON */
      ccode : -1
    }
  },
  methods:{
   async loadPaginatedEstore(){
      const options = {
        params: {
          page: 1,
          /* additional parameters */
        }
      };
      const response = await loadPaginatedEstore();
      this.isBCOAccount = response.data.isBCOAccount;

    },
    async getiglowvotingpower(){
      const response = await getIGlowVotingPower();
      this.votingPower = response.data.votingPower;
    },
    async getCcode(){
      const response = await getUserCountryCode();
      this.ccode = response.data.ccode;
    },
  },
  mounted(){
    this.getiglowvotingpower();
    this.loadPaginatedEstore();
    this.getCcode();
  }
});
