import request from '@/utils/request'

//Login
export function loadPaginatedEstore(page) {
  return request({ url: 'vestore', method: 'post', data: {'page': page} })
} 

//Send Estore Product
export function send_instore(params) {
  return request({ url: 'estore_send', method: 'post', data: params })
} 


//Get Destination Usid Fullname
export function getFullName(params) {
  return request({ url: 'gettousidfullname', method: 'post', data: params })
} 

export function isShowRetroPMAButton() {
  return request({ url: 'visShowRetroPMAButton', method: 'get'})
} 

export function proceedConfirmTransferProduct(params) {
  return request({ url: 'proceedtransferproduct', method: 'post', data: params })
} 

export function viewExpiryDate(prodcode) {
  return request({ url: 'vgetestoreexpiredate/' + prodcode, method: 'get'})
} 


export function getIGlowCandidates() {
  return request({ url: 'viglowcandidates', method: 'post', data: {x:'x'} })
} 


export function getIGlowVotingPower() {
  return request({ url: 'vgetiglowvotingpower', method: 'post', data: {x:'x'} })
} 

export function sendVote(params) {
  return request({ url: 'vsendvote', method: 'post', data: params })
} 


export function processIGlowCode(params){
  return request({ url: 'vprocessiglowcode', method: 'post', data: params })
}

export function loadPaginatedEstoreTrans(params) {
  return request({ url: 'estoretranshistory', method: 'post', data: params })
} 

export function loadPaginatedClaimedECCOrders(page) {
  return request({ url: 'vgetclaimedeccorders', method: 'post', data: {'page': page} })
} 

export function claimECCOrder(params) {
  return request({ url: 'vclaimeccorder', method: 'post', data: params })
} 